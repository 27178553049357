import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectList from "../components/project-list"
import { StaticImage } from "gatsby-plugin-image"

const HomePage = ({ data, location }) => {
  const projects = data.allMdx.nodes
  return (
    <Layout location={location}>
      <div className="flex flex-wrap sm:flex-nowrap items-center pt-0 sm:pt-20 pb-20 sm:pb-44">
        <StaticImage
          src="../images/profile_index.png"
          alt="Menghan's profile image"
          placeholder="dominantColor"
          className="w-44 h-44 flex-shrink-0"
        />
        <p className="font-normal text-2xl sm:text-4xl ml-0 sm:ml-20 leading-normal sm:leading-normal mr-10 sm:mr-20 mt-12 sm:mt-0">
          I'm <span className="font-semibold text-menghan">Menghan</span>,
          currently designing for ChromeOS at{" "}
          <span className="font-semibold">Google</span> to help people seamlessly share, connect, and move across devices.
        </p>
      </div>
      <div>
        <Seo title="Home" />
        <ProjectList projects={projects} />
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___index], order: ASC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          subtitle
          company
          index
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 90
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`
